.footer-main-container {
    height: 75vh;
    width: 100%;
    /* background: #000; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    /* margin-top: 4%; */
    /* box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2); */
    /* box-shadow:  20px 20px 60px #d9d9d9,
               -20px -20px 60px #ffffff; */
  }
  
  .footer-container-1 {
    /* height: 0.4%; */
    width: 100%;
    background: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.5px solid grey;
  }
  
  .footer-container-1-social-container{
    display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-right: 20px;
  }
  
  .footer-container-1-title {
    font: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-3);
    color: var(--secondary-font-color);
    margin-left: 30px;
  }
  
  .footer-container-2 {
    height: 75%;
    width: 100%;
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-container-2-left {
    width: 25%;
    height: 100%;
    /* background: rgb(197, 196, 196); */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-container-2-left-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }
  
  .footer-container-2-left-title {
    width: 70%;
    font: var(--primary-font);
    font-style: normal;
    font-weight: 900;
    font-size: var(--font-size-heading-2);
    color: var(--font-color-3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

   .footer-container-2-left-title > img {
    height: 40%;
    width:50%;
    object-fit: cover;
   }
  
  .footer-container-2-left-content {
    width: 70%;
    font: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-3);
    color: var(--font-color-3);
    text-align: center;
  }
  
  .footer-container-2-right {
    width: 65%;
    height: 100%;
    /* background: rgb(16, 151, 48); */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-container-2-right-warp {
    display: flex;
    gap: 20px;
  }
  
  .footer-container-2-right-column {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .footer-container-2-right-title {
    font: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-3);
    color: var(--font-color-3);
  }
  
  .footer-container-2-right-content {
    font: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-3);
    color: var(--font-color-3);
    cursor: pointer;
  }
  
  .footer-container-2-right-content:hover {
    /* font-size: var(--font-size-3); */
    color: var(--primary-button-color);
    cursor: pointer;
  }
  
  .footer-container-3 {
    height: 10%;
    width: 100%;
    background: #bababa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-container-3-content {
    font: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-4);
    color: var(--font-color-3);
  }
  
  
  @media only screen and (max-device-width: 760px) {
    .footer-main-container {
      height: fit-content;
    }
    .footer-container-2 {
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px;
    }
    .footer-container-1-social-container{
      margin-right: 0;
      padding: 2vh;
  }
  .footer-container-2-left {
    width: 90%;
  }
    .footer-container-2-left {
      margin: 10px;
    }
    .footer-container-2-right {
      width: 100%;
      align-items: flex-start;
      margin: 10px;
    }
    .footer-container-2-right-warp {
      width: 100%;
      flex-direction: column;
    }
  }
  
  @media only screen and (max-device-width: 420px) {
    .footer-main-container {
      height: fit-content;
    }
    .footer-container-2-left-title {
      text-align: left;
    }
    .footer-container-2-left-content {
      text-align: left;
    }
    .footer-container-2-right-title {
      text-align: left;
    }
    .footer-container-2-right-content {
      text-align: left;
    }
    .footer-container-2 {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    .footer-container-2-left {
      margin: 10px;
    }
    .footer-container-2-right {
      width: 100%;
      align-items: flex-start;
      margin: 10px;
    }
    .footer-container-2-right-warp {
      width: 100%;
      flex-direction: column;
    }
  }
  