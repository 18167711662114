.About-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 17vh; */
    /* gap: 1vh; */
    /* position: absolute;
    top: 17%; */

}
.About-Text-content{
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items:center ;
}
.about-text-paragraph{
    height: 90%;
    width: 85%;
    display: flex;
    
    color: black;
    flex-direction: column;
  
}

._AboutUs-content-title{
    width: 95%;
    font-size: var(--font-size-2);
    font-weight: 300;
    text-align: justify;
    word-spacing: 10px;
    line-height: 1.3;
}

ul li::marker {
    color: rgb(0, 0, 0);
    font-size: 1.5em;
  }