.BlogBox-main-container {
  height: 50vh;
  width: 20vw;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 5vh;
  background: #ffffff;
  gap: 3%;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.BlogBox-image-container {
  height: 50%;
  width: 100%;
  /* background: #9f1111; */
}

.BlogBox-image-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.BlogBox-title-container {
  height: 10%;
  width: 100%;
  padding-left: 5%;
  /* background: #32dc65; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.BlogBox-title {
  font-size: var(--font-size-4);
  font-weight: 700;
}

.BlogBox-description-container {
  height: 30%;
  width: 95%;
  padding-left: 2.5%;
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.BlogBox-description {
  font-size: var(--font-size-5);
  font-weight: 300;
  text-align: justify;
}

.BlogBox-details-container {
  height: 10%;
  width: 100%;
  /* background-color: bisque; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlogBox-Date {
  height: 100%;
  width: 50%;
  /* background: #a01717; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
  font-size: var(--font-size-5);
  font-weight: 300;
}

.BlogBox-ReadMore {
  height: 100%;
  width: 50%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  font-size: var(--font-size-4);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}


.BlogBox2-main-container{
  height: 15vh;
  width: 100%;
  /* background: #1aef2c; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* gap: 1.5vh; */
}

.BlogBox2-image-container{
  height: 80%;
  width: 30%;

}

.BlogBox2-image-container > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.BlogBox2-details_container{
  height: 80%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: #000; */
  gap: 10%;
}

.BlogBox2-title-container{
  height: 30%;
  width: 100%;
  padding-left: 5%;
  /* background: #32dc65; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.BlogBox2-description-container{
  height: 70%;
  width: 95%;
  padding-left: 2.5%;
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-all;
}