.Playstore-post-main-container{
    height: fit-content;
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-3);
    border-radius: 15px;
    padding: 2vh;
}

.Playstore-post-left-container{
    height: 100%;
    width: 50%;
    /* background: #000; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.AppMockUp{
    height: 50%;
    width: 50%;
    object-fit: contain;
}

.Playstore-post-right-container{
    height: 100%;
    width: 50%;
    /* background: #f00909; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
}

.GooglePlayButton{
    height: 100%;
    width: 18%;
    cursor: pointer;
    /* background: #000; */
}

@media only screen and (max-device-width: 760px) {
    .Playstore-post-main-container{
        flex-direction: column;
    }

    .Playstore-post-left-container{
        width: 90%;
    }
    
    .Playstore-post-right-container{
        width: 95%;
    }
}