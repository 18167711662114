.seo-main-container{
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* margin-top: 17vh; */
        /* gap: 1vh; */
        /* position: absolute;
        top: 17%; */
    
}