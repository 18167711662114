.section-Wrapper-bottom {
  height: fit-content;
  width: 100%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
}

.section-Wrapper-bottom-left {
  height: 100%;
  width: 100%;
  /* background: #d62121; */
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.section-Wrapper-bottom-left-content {
  height: 80%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: left baseline;
  text-align: justify;
  color: var(--color-2);
  font-family: var(--primary-font);
  word-spacing: 8px;
  /* font-family: var(--primary-font); */
  /* font-family: sans-serif; */  
  /* line-height: 1.4;
  font-weight: 350; */
  /* font-family: "poppins"; */
  font-size: 1.2vw;
  /* background: #000; */
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  line-height: 26px;
  font-weight: 400;
  
}

.section-Wrapper-bottom-right {
  height: 100%;
  width: 50%;
  /* background: #21d63c; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-Wrapper-bottom-right-content {
  height: 80%;
  width: 70%;
  
}

.section-Wrapper-bottom-right-content > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.title {
  margin-top: 5%;
  width: 100;
}
.description {
  width: 70%;
  font-size: medium;
}

@media only screen and (max-device-width: 760px) {
  .section-Wrapper-bottom-left {
    width: 100%;
    height: 50%;
  }
  .section-Wrapper-bottom-right {
    width: 100%;
    height: 50%;
  }

  .section-Wrapper-bottom-left-content{
    font-size: 2.5vw;
  }
}