.rfid-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 17vh; */
    gap: 2vh;
    /* position: absolute;
    top: 17%; */

}
.Rfidcard-container {
    height: 45vh;
    width: 23%;
    display: flex;
    align-items: center;
    /* background-color: rgb(22, 205, 37); */
  }
  .Rfidcard-box {
    height: 90%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 22px;
    background: linear-gradient(145deg, #bcb3b3, #e0d5d5);
    box-shadow: 1px 1px 0px #cfc5c5, -1px -1px 0px #d3c9c9;
    margin: 2%;
    padding: 3%;
  }
  .RfidCard_image {
      height: 70%;
      width: 100%;
      justify-content: center;
      align-items: center;
  
  }
  .RfidCard_image > img{
      height: 100%;
      width:100%;
      object-fit: contain;
  justify-content: center;
  align-items: center;
  }
  .Rfidcard-title{
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: var(--font-size-3);
      font-weight: 700;
  }
  .Rfidcard-description{
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center; 
      
      font-size: var(--font-size-4);
  }
  .RfidcontentWrapper{
    height: 40%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
  }