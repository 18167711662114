.Intro-title-container{
    height: fit-content;
    width: 100%;
    /* background: #870b0b; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.Intro-title{
    font-size: var(--font-size-1);
    font-weight: 800;
    color: var(--color-1);
}

.Intro-subtitle-container{
    height: fit-content;
    width: 100%;
    /* background: #23d010; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.Intro-subtitle{
    font-size: var(--font-size-1);
    font-weight: 400;
    color: var(--color-2);
    text-align: center;
}

.Intro-description-container{
    height: 10%;
    width: 50%;
    /* background: #570abb; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.Intro-description{
    font-size: var(--font-size-3);
    font-weight: 200;
    color: var(--color-2);
    text-align: center;
}