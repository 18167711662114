.schoolmanagement-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:2vh;
    /* position: absolute;
    top: 17%; */
}