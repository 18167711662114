.WhyGenericCard-main-container{
    height: 35vh;
    width: 70%;
    /* background: #d7d7d7; */
    
    display: flex;
    align-items: center;
    justify-content: center;

}

.WhyGenericCard-left-container{
    height: 100%;
    width: 50%;
    /* background: #f9f9f9; */
    /* background: var(--color-3); */
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8%;
}

.WhyGenericCard-left-contact-info{
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
    font-size: var(--font-size-4);
    font-weight: 700;
    color: green;
    gap: 5%;
}

.WhyGenericCard-left-title{
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
    font-size: var(--font-size-2);
    font-weight: 700;
}

.WhyGenericCard-left-description{
    height: fit-content;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
    text-align: justify;
    font-size: var(--font-size-4);
    font-weight: 400;
}

.WhyGenericCard-right-container{
    height: 100%;
    width: 45%;
    /* 
    background: #f34f4f; */
}
.WhyGenericCard-right-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media only screen and (max-device-width: 760px) {
    .WhyGenericCard-main-container{
        height: fit-content;
    }

    .WhyGenericCard-main-container{
        width: 95%;
        flex-direction: column-reverse;
    }

    .WhyGenericCard-left-container{
        width: 95%;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }

    .WhyGenericCard-right-container{
        width: 95%;
    }
    
    
}