.SectionOne-wrapper {
    width: 100%;
    height: 70vh;
    margin-top: 6%;
    background: linear-gradient(to bottom, rgba(244, 250, 248, 1), rgba(244, 250, 248, 0.818));
    background-size: cover;
    transition: background-image 500ms ease-in-out;
    /* box-shadow: 0 3px 5px rgba(57, 63, 72, 0.288); */
    /* border-bottom: 0.5px solid gray; */
}

.main-image  {
    width: 55%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-image > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.Content-Section{
    height: 100%;
    width: 100%;
}

.SectionOne-Details-Container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.sectionOne-title{
    height: fit-content;
    width: 100%;
    display: flex;
    vertical-align: top;
   justify-content: center;
    color: var(--font-color-1);
    font-size: var(--font-size-1);
    font-weight: 800;
}
.description{
    color:var(--font-color-1);
    font-size: var(--font-size-2);
}

