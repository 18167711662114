.sectionThree-main{
    height:70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
    background-color: #5EBD87;
}

.sectionThree-title{
    height: fit-content;
    width: 100%;
    display: flex;
    vertical-align: top;
   justify-content: center;
    color: white;
    font-size: var(--font-size-2);
    font-weight: 800;
   
}
.sectionThreeCenter-box{
    width:70%;
    height:15%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    padding: 1%;
    border-radius: 10px;
    /* flex-direction: column; */
    background:rgb(253, 253, 253);
}


.SectionThreeBox-image{
    width: 15%;
    height: 70%;
    /* background:rgb(157, 66, 66); */
    display: flex;
    flex-direction: column;
    
}
.SectionThreeBox-title{
    width: 15%;
    background: white;
    flex-direction: column;
    
}

.SectionThreeSubtitle{
    height: fit-content;
    width: 70%;
    /* background: #21d63c; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.SectionThreeBox-SubTitle{
    height: 80%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    color: white;
}
.SectionThree-subimage{
    height: 100%;
    width: 50%;
    /* background: #5121d6; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionhree-subimage-image{
    height: fit-content;
    width: 70%;
    /* background: #000; */
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionhree-subimage-image > img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}


.customBoxcontainer{
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    /* background: #4de962; */
}

.customBoxcontainer > img {
    height : 60%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

@media only screen and (max-device-width: 760px) {
    .sectionThree-main{
        height: fit-content;
        padding: 1vh;
    }

    .sectionThreeCenter-box{
        width: 95%;
        height: fit-content;
    }

    .sectionhree-subimage-image{
        width: 100%;
    }

    .customBoxcontainer>img{
        height: 25%;
        width: 60%;
    }

    .SectionThreeSubtitle{
        width: 100%;
        flex-direction: column-reverse;
        gap: 2vh;
    }

    .SectionThreeBox-SubTitle, .SectionThree-subimage{
        width: 90%;
    }

}