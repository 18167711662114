.industrymanagement-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
    /* position: absolute;
    top: 17%; */
}
.Sectionfourmain{
   
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background-color: blue; */
}
.sectionfour-left{
    height: 80%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}
.sectionfour-text{
    height: 100%;
    width: 80%;
    color: aliceblue;
}
.sectionfour-right{
     height: 80%;
    width: 50%;
    display: flex;
    /* background-color: rgb(107, 22, 60); */
}
.sectionfour-right-image > img{
    color: black;
    object-fit: contain;
    width: 80%;
    height: 80%;
}
.SectionFourHead-title{
    color: black;
    font-weight: 400;
    font-size: xx-large;
}
.m-0{
    text-align: justify;
}

@media only screen and (max-device-width: 760px) {

.Sectionfourmain{
    flex-direction: column;
}
}