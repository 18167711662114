.video-carousel-container {
    width: 100%;
    height: 90vh;
    margin: 0;
    padding: 0;
  }
  
  .video-slide {
    width: 100%;
    position: relative;
  }
  
  .video-overlay {
    height: fit-content;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    /* text-align: center; */
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4vh;

    /* background: #000; */
  }
  
  .video-title {
    height: fit-content;
    width: 25vw;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    color: rgb(30, 30, 30);
    /* background: #d31010; */
    /* color: var(--font-color-1); */
    font-size: var(--font-size-heading-2);
    font-weight: 800;
   
  }
  
  .video-description {
    width: 25vw;
    color: rgb(30, 30, 30);
    font-size: var(--font-size-2);
    text-align: left;
    /* background: #000; */
  }
  
  .video-element {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    /* position: fixed; */
  }