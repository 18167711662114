.BlogList-main-container{
    height: fit-content;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10%;
    /* background: #000; */
}

.BlogList-container-Wrapper{
    height: fit-content;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10%;
    margin: 5vh 0 5vh 0;
    /* overflow-y: auto; */
}

.space{
    height: 10vh;
    width: 100spa;
}