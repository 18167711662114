.ClientLogo_main-container{
    height: fit-content;
    width: 80vw;
    flex-wrap: wrap;
    display: flex;
    padding-left: 7%;
    margin: 10vh 0 10vh 0;
}
.clientLogo-main-image-box{
    height: 35vh;
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 10px 10px 17px ;
   
    margin: 8px;
    border-radius: 10% 10% 10% 10%;
    border: 0.5px grey solid;
    /* background: #000; */
    /* box-shadow: 2px 2px rgba(53, 55, 56, 0.236); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.ClientLogo-image{
 height: max-content;
    object-fit: contain;
    width: 100px;
    border-radius: 50%;
    align-self: center;
}
@media only screen and (max-device-width: 760px) {
    .clientLogo-main-image-box {
            width:40%;
            height: 70%;
            align-items: flex-start;
            justify-content: flex-start;
    }
    }
    @media only screen and (max-device-width: 760px) {
        .ClientLogo-image {
                width:90%;
                align-items: flex-start;
                justify-content: flex-start;
        }
        }