.VideoBox-main-container {
    width: 100%;
    height: 88vh;
    margin: 0;
    padding: 0;
}

.VideoBox-element {
    width: 100%;
    height: 88vh;
    object-fit: cover;
}

.VideoBox-filter {
    width: 100%;
    height: 88vh;
    background-color: #97DAEF;
    opacity: .6;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.VideoBox-overlay {
    height: fit-content;
    align-self: center;
    width: 100%;
    height: 80vh;
    /* padding: 10vw; */
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6vh;
    z-index: 1;
}

.VideoBox-sub-title{
    font-size: 2.5vw;
    font-family: "poppins";
    font-weight: 900;
    text-align: center;
    color: blue;
}

.VideoBox-title {
    font-size: 2.5vw;
    font-family: "Segoe UI";
    font-weight: 900;
    text-align: center;
    color: #374047;
}

.VideoBox-description {
    width: 60%;
    font-size: var(--font-size-3);
    font-weight: 300;
    font-family: "Segoe UI";
    text-align: center;
}

.VideoBox-button-container {
    
    display: flex;
    
    flex-direction: row;
    gap: 50px;
}

.VideoBox-button {
    height: fit-content;
    min-width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    font-size: var(--font-size-3);
    font-weight: 700;
    color: var(--color-1);
    background-color: var(--color-5);
    border: 0;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.VideoBox-button-card-container {
    width: 100%;
    padding-top: 10vh;
    /* background: #000; */
    display: flex;
    /* flex-direction: row; */
    /* padding-top: 10vh; */
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.VideoBox-button-card {
    height: fit-content;
    /* width: 26vw; */
    cursor: pointer;
    /* padding: 15px 40px 15px 40px; */
    padding: 10px;
    border-radius: 10px;
    font-size: var(--font-size-6);
    font-weight: normal;
    color: var(--color-1);
    background-color: var(--color-3);
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}

.VideoBox-button-card-image {
    height: 40px;
    width: 40px;
}


@media only screen and (max-device-width: 760px) {
    .VideoBox-title {
        font-size: 5vw;
        font-weight: bold;
    }

    .VideoBox-button-container {
        flex-direction: column;
        gap: 10px;
    }

    .VideoBox-button-card-container {
        flex-direction: column;
        gap: 20px;
    }

    .VideoBox-overlay {
        gap: 3vh;
    }
}