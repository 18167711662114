.ServiceMenu-main-container{
    height: fit-content;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: var(--color-5); */
    padding: 10px;
    border-bottom: "2px solid black";
}
.Menu-container{
    font-size: medium;
}

.Custom_Button_1{

}

._active{
    color: var(--color-1);
    background: var(--color-3);
    font-weight: 900;
}

@media only screen and (max-device-width: 760px) {

        .ServiceMenu-main-container{
            display: none;
        }
    }