.ContactUs-main-container {
  height: fit-content;
  width: 95%;
  margin-top: 1.5vh;
  /* background: #60b825; */
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  /* flex-direction: column; */
}

.ContactUs-left-container {
  height: 100%;
  width: 50%;
  /* background: #d1f072; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
}

.ContactUs-left-title {
  /* margin-top: 5vw; */
  /* margin-left: 5vw; */
  font-size: var(--font-size-1);
  font-weight: 800;
  color: var(--color-1);
}

.ContactUs-left-address {
  /* margin-left: 5vw; */
  font-size: var(--font-size-1);
  font-weight: 400;
  color: var(--color-2);
  /* text-align: right; */
}

.Contact-left-content-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.ContactUs-left-content {
  /* margin-left: 5vw; */
  font-size: var(--font-size-3);
  font-weight: 200;
  color: var(--color-2);
  text-align: center;
}

.ContactUs-left-gif{
    height: 78vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    /* background: #000; */
}

.ContactUs-left-gif > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ContactUs-right-container {
  height:100%;
  width: 50%;
  /* background: #f0c872; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContactUs-right-form {
  height: fit-content;
  width: 95%;
  margin: 0px 10px 10px 10px;
  padding: 0px 15px 15px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
/* background: linear-gradient(225deg, #efefef, #ffffff);
box-shadow:  -9px 9px 17px #eeeeee,
             9px -9px 17px #ffffff; */
}

.form-container{
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  /* background: #000; */
}

.label-home{
  font-size: var(--font-size-3);
  color: var(--color-1);
  font-weight: 500;
}

input::placeholder, textarea::placeholder {
  font-size: var(--font-size-3);
  color: var(--color-1);
  font-weight: 500;
}

.form-input-home{
    height: 7.2vh;
    width: 70%;
    padding-left: 2%;
    border-radius: 10px;
    border: 0;
    background: linear-gradient(225deg, #efefef, #ffffff);
    box-shadow:  -9px 9px 17px #eeeeee,
                 9px -9px 17px #ffffff;
}

.form-text-home{
  height: 12vh;
  width: 70%;
    border-radius: 10px;
    padding-left: 2%;
    /* border: 0.8px solid rgb(60, 60, 60); */
    border: 0;
    background: linear-gradient(225deg, #efefef, #ffffff);
    box-shadow:  -9px 9px 17px #eeeeee,
                 9px -9px 17px #ffffff;
}

.contact-submit-home{
    height: 5vh;
    width: 30%;
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    font-size: var(--font-size-3);
    font-weight: 600;
    background-color: var(--color-1);
    color: #fff;
}

.contact-form-input-warp-home{
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

@media only screen and (max-device-width: 760px) {
  .ContactUs-main-container{
    flex-direction: column;
  }

  .ContactUs-left-container{
    justify-content: center;
    width: 100%;
  }

  .ContactUs-left-gif{
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  .ContactUs-right-container{
    width: 100%;
  }

  .form-input-home, .form-text-home{
    width: 90%;
  }
}