.testimonial-box-container {
  /* height: 60vh; */
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  padding: 3%;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  /* margin: 10px; */
}

.testimonial-image-container{
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #000; */
}

.testimonial-image {
  /* width: 10vw;
  height: 50%; */
  height: 100px;
  object-fit: cover;
  width: 100px;
  border-radius: 50%;
  align-self: center;
}

.testimonial-text-container {
  display: flex;
  height: 70%;
  width: 90%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background: #d60808; */
}

.testimonial-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  margin-bottom: -0.5vh;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
}

.testimonial-description {
  height: 50%;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
}
.testimonial-subtitle{
  height:30%;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 100;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  font-size: var(--font-size-3);
  color: var(--font-color-3);

}  
@media only screen and (max-device-width: 760px) {
.testimonial-box-container {
        width:90%;
        align-items: flex-start;
        justify-content: flex-start;
}
}
