.Service-main-container{
    height: fit-content;
    width: 100%;
    /* background: #65d0c5; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5vh;
    overflow: hidden;
}



.Service-button-container{
    height: 10vh;
    width: 100%;
    /* background: #e1128e; */
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 5%;
    flex-wrap: wrap;
}

.Service-button.active{
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    font-size: var(--font-size-3);
    font-weight: 600;
    color: var(--color-1);
    background-color: var(--color-5);
    border: 0;
}



.Service-button{
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: var(--font-size-3);
    font-weight: 300;
    color: rgb(42, 42, 42);
    background-color: transparent;
}

.Service-Card-container{
    height: fit-content;
    width: 100%;
    padding-top: 2vh;
    /* background: #7ad763bb; */
    display: flex;
    align-items: center;
    /* align-self: center; */
    justify-content: center;
    /* gap: 10px; */
    /* overflow: hidden; */
}

.p-carousel{
    width: 95%;
    height: fit-content;
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    /* background-color: #7ad763bb; */
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background-color: var(--color-1);
    display: flex;
}


@media only screen and (max-device-width: 760px) {
    .Service-button-container{
        padding-left: 3%;
        align-items: flex-start;
        justify-content: center;
    }
}

@media only screen and (max-device-width: 760px) {
    .Service-Card-container{
        padding-left: 3%;
        height: 30%;
        align-items: flex-start;
        justify-content: center;
    }
}