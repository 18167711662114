:root {
  --primary-background-color: #ffffff;
  --secondary-background-color: rgba(235, 242, 233, 0.4);
  --button-color-1: #2d4010;
  --box-color: #ebf2e9;
  font-size: 1.1vw;
  --primary-font: "Segoe UI";
  --color-1: #05191fe7;
  --color-2: #373935;
  --color-3: #deedf2e2;
  --color-4: #377bbc;
  --color-5: #b7fa99;
  --font-color-1: #000;
  --font-size-heading-1: 6rem;
  --font-size-heading-2: 3rem;
  --font-size-1: 2rem;
  --font-size-2: 1.5rem;
  --font-size-3: 1rem;
  --font-size-4: 0.8rem;
  --font-size-5: 0.5rem;
  --font-size-6: 1.2rem;
  --font-size-7: 0.65rem
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  font-size: var(--font-size-4);
}

.p-menuitem-text {
  font-size: var(--font-size-4);
}

#masthead ul li:hover > ul {
  width: 100%;
  overflow: hidden;
}

body {
  overflow-x: hidden;
  background: #fff;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;

}



@media only screen and (max-device-width: 760px) {
  :root {
    font-size: 2vw;
  }
}

@media only screen and (max-device-width: 420px) {
  :root {
    font-size: 3vw;
    text-align: center;
  }

  .p-menubar .p-menubar-root-list{
    width: auto;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list{
    width: 40vw;
    left: auto;
    margin-right: 40vw;
  }

  .p-menubar .p-menubar-root-list .p-submenu-list{
    width: 50vw;
  }
}

body {
  overflow-x: hidden;
  background: #fff;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;

}

body::-webkit-scrollbar {
  display: none;
}


.magicpattern { 
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cfilter id=%22a%22%3E%3CfeTurbulence result=%22TURBULENCE%22 baseFrequency=%22.063%22 seed=%221%22%2F%3E%3CfeDisplacementMap in=%22SourceGraphic%22 in2=%22TURBULENCE%22 scale=%2220%22%2F%3E%3C%2Ffilter%3E%3Cfilter id=%22b%22%3E%3CfeOffset dx=%2230%22 result=%22OFFSET-1%22%2F%3E%3C%2Ffilter%3E%3Cfilter id=%22d%22%3E%3CfeOffset dx=%2220%22 result=%22OFFSET-2%22%2F%3E%3C%2Ffilter%3E%3Cfilter id=%22e%22%3E%3CfeOffset dy=%2250%22 result=%22OFFSET-3%22%2F%3E%3C%2Ffilter%3E%3Cmask id=%22c%22%3E%3Cpath fill=%22%23fff%22 d=%22M0 0h1000v1000H0z%22%2F%3E%3Ccircle cx=%22260%22 cy=%22677%22 r=%229%22 filter=%22url(%23a)%22%2F%3E%3Ccircle cx=%22328%22 cy=%22299%22 r=%227%22 filter=%22url(%23a)%22%2F%3E%3Ccircle cx=%22289%22 cy=%22647%22 r=%229%22 filter=%22url(%23a)%22%2F%3E%3Ccircle cx=%22246%22 cy=%22768%22 r=%2214%22 filter=%22url(%23a)%22%2F%3E%3Cg filter=%22url(%23b)%22 mask=%22url(%23c)%22%3E%3Cpath fill=%22none%22 stroke-width=%228%22 stroke=%22%23000%22 d=%22M817 718.5Q633.053 890.275 384 854%22 filter=%22url(%23a)%22%2F%3E%3C%2Fg%3E%3Cg filter=%22url(%23d)%22 mask=%22url(%23c)%22 transform=%22scale(.6)%22%3E%3Cpath fill=%22none%22 stroke-width=%226%22 stroke=%22%23000%22 d=%22M817 718.5Q623.795 860.69 384 854%22 filter=%22url(%23a)%22%2F%3E%3C%2Fg%3E%3Cg filter=%22url(%23e)%22%3E%3Cpath fill=%22none%22 stroke-width=%229%22 stroke=%22%23000%22 d=%22M113.5 490.5q63.92-222.967 257-351.5%22 filter=%22url(%23a)%22%2F%3E%3C%2Fg%3E%3Cg filter=%22url(%23e)%22%3E%3Cpath fill=%22none%22 stroke-width=%226%22 stroke=%22%23000%22 d=%22M816 284q73.5 217.082 1 434.5%22 filter=%22url(%23a)%22%2F%3E%3C%2Fg%3E%3C%2Fmask%3E%3C%2Fdefs%3E%3Cg mask=%22url(%23c)%22%3E%3Cpath filter=%22url(%23a)%22 fill=%22%2397DAEF%22 d=%22M817 718.5Q642 937 384 854T113.5 490.5Q101 210 370.5 139T816 284q176 216 1 434.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
