.Landing-main-container {
    width: 100%;
    height: 70vh;
    /* -webkit-box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3);
    box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3); */
    margin: 0;
    padding: 0;
    position: relative;
    animation: fadeIn 500ms ease-in-out forwards;
}

/* .selectors {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    background: rgba(0, 0, 0, 0.1);
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.selectors .selector {
    border: 1px solid var(--color-2);
    background: none;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 5px 0;
    opacity: 0.6;
    cursor: pointer;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.selectors .selector:hover {
    background: white;
    opacity: 0.9;
    height: 11px;
    border-radius: 50%;
}

.selectors .selector.active {
    background: var(--color-1);
    opacity: 0.9;
    height: 30px;
    border-radius: 40%;
} */

section.wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: background-image 500ms ease-in-out;
}

.imageblock {
    width: 60%;
    height: 75%;
    object-fit: contain;
    color: #fff;
    /* -webkit-transform: scale(4); */
    /* transform: scale(4); */
    /* -webkit-animation: imageblocktransformer 1s ease-in 0s 1;
    animation: imageblocktransformer 1s ease-in 0s 1; */
    position: absolute;
    top: 45%;
    /* bottom: 50%; */
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex;
    z-index: 1;
}


/* @-webkit-keyframes imageblocktransformer {
    from {
        -webkit-transform: scale(1, 0.1);
    }

    to {
        -webkit-transform: scale(4);
    }
}

@keyframes imageblocktransformer {
    from {
        transform: scale(0.1);
    }

    to {
        transform: scale(4);
    }
} */

.imageblock2 {
    width: 5%;
    height: 10%;
    object-fit: contain;
    color: #fff;
    -webkit-transform: scale(4);
    transform: scale(4);
    -webkit-animation: imageblock2transformer 1s ease-in 0s 1;
    animation: imageblock2transformer 1.5s ease-in 0s 1;
    position: absolute;
    /* top: 65%; */
    bottom: 25%;
    right: 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

@-webkit-keyframes imageblock2transformer {
    from {
        -webkit-transform: scale(0.1);
    }

    to {
        -webkit-transform: scale(4);
    }
}

@keyframes imageblock2transformer {
    from {
        transform: scale(0.1);
    }

    to {
        transform: scale(4);
    }
}

.imageblock3 {
    width: 5%;
    height: 10%;
    object-fit: contain;
    color: #fff;
    -webkit-transform: scale(3);
    transform: scale(3);
    -webkit-animation: imageblock3transformer 1s ease-in 0s 1;
    animation: imageblock3transformer 2s ease-in 0s 1;
    position: absolute;
    top: 25%;
    /* bottom: 45%; */
    right: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

@-webkit-keyframes imageblock3transformer {
    from {
        -webkit-transform: scale(0.1);
    }

    to {
        -webkit-transform: scale(3);
    }
}

@keyframes imageblock3transformer {
    from {
        transform: scale(0.1);
    }

    to {
        transform: scale(3);
    }
}



.floater {
    height: 30vw;
    width: 30vw;
    position: absolute;
    top: 0;
    left: 10;
    opacity: .15;
    animation: 6s ease-in-out infinite float
}

@keyframes float {

    0%,
    100% {
        transform: translate(0)
    }

    50% {
        transform: translate(-30px)
    }
}

.rotator {
    height: 20vw;
    width: 20vw;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .15;
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
}



@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

aside.panel {
    width: 40vw;
    height: 100%;
    /* color: var(--color-1); */
    transition: background 500ms ease-in-out;
}

aside.panel h2.panel-header,
aside.panel p.panel-info {
    margin: 0;
    padding: 1.3rem 3.5rem;
}

aside.panel h2.panel-header {
    padding-top: 2em;
    font-weight: normal;
    font-size: 2.5rem;
    color: var(--color-1);
    text-transform: capitalize;
}

aside.panel p.panel-info {
    font-size: 1.2rem;
    line-height: 1.8em;
    color: var(--color-2);
}

aside.panel button.panel-button {
    margin: 1.3rem 3.5rem;
    padding: 0.8em 1.3em;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: box-shadow 0.5s ease-in-out, color 0.5s ease-in-out;
}

aside.panel button.panel-button:hover {
    box-shadow: inset 0 0 1.5em 1.5em #fff;
}

aside.panel button.panel-button:focus {
    outline: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}