.testimonials-carousel-main-container {
  height: fit-content;
  width: 100%;
  /* background: rgb(255, 255, 255); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5vh;
}

.testimonials-carousel-title {
  height: fit-content;
  width: 80%;
  /* background: rgb(139, 139, 139); */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font: var(--primary-font);
  /* font-style: normal; */
  font-size: var(--font-size-1);
  font-weight: 800;
  color: var(--color-1);
  text-align: right;
}

.testimonials-carousel-subtitle{
    height: fit-content;
  width: 80%;
  /* background: rgb(139, 139, 139); */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font: var(--primary-font);
  /* font-style: normal; */
  font-size: var(--font-size-1);
  font-weight: 400;
  color: var(--color-2);
  text-align: right;
}

.testimonials-carousel-description{
    height: fit-content;
    width: 80%;
    /* background: rgb(139, 139, 139); */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: var(--primary-font);
    /* font-style: normal; */
    font-size: var(--font-size-3);
    font-weight: 200;
    color: var(--color-2);
    text-align: right;
}

@media only screen and (max-device-width: 760px) {
  .testimonials-carousel-main-container {
    flex-direction: column;
    /* display: none; */
  }
}
