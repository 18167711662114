.Home-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
    /* position: absolute; */
    margin-top: 10vh;
}

@media only screen and (max-device-width: 760px) {
    .Home-main-container{
        margin-top: 4vh;
    }
}