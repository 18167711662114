.LandingSection-Main-Container {
  height: 80vh;
  width: 100%;
  margin-top: 6%;
  /* background: linear-gradient(to bottom, rgba(244, 250, 248, 1), rgba(244, 250, 248, 0.818)); */
  /* background: #000; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.LandingSection-Details-Container {
  height: 100%;
  width: 100%;
  padding-left: 2%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    to right,
    rgba(0, 5, 3, 0.564),
    rgba(10, 37, 28, 0.082)
  );
}

.LandingSection-title {
  height: fit-content;
  width: 100%;
  display: flex;
  vertical-align: top;
  justify-content: flex-start;
  text-align: left;
  color: aliceblue;

  /* color: var(--font-color-1); */
  font-size: var(--font-size-heading-2);
  font-weight: 800;
}

.LandingSection-description {
  /* width: 50%; */
  /* color: var(--font-color-1); */
  color: aliceblue;
  font-size: var(--font-size-2);
  text-align: left;
}

@media only screen and (max-device-width: 760px) {
  .LandingSection-Main-Container{
    height: 50vh;
    margin-bottom: 5vh;
  }

  .LandingSection-Details-Container {
    align-items: center;
    padding-left: 0%;
  }

  .LandingSection-title, .LandingSection-description {
    text-align: center;
    justify-content: center;
  }
}