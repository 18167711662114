.AboutUs-main-container{
    height: fit-content;
    width: 100%;
    /* background: var(--color-3); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2vh;
    padding-bottom: 2vh;
    gap: 1.5vh;
}

.AboutUs-button-container{
    height: 10vh;
    width: 100%;
    /* background: #e1128e; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

.AboutUs-Card-container{
    height: fit-content;
    width: 100%;
    /* background: #7ad763bb; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: fixed; */
    /* gap: 10px; */
    /* overflow-x: scroll; */
    /* align-items: center; */
}
.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.timeline{
    height: 100%;
    width: 80%;
    object-fit: cover;
}

.customized-timeline{
    /* gap:200px; */
    /* width: 10px; */
}





@media screen and (max-width: 760px) {
    .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }
    .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }
    .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }
    .customized-timeline .p-card {
        margin-top: 1rem;
    }
}