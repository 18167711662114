.Blog-Content-main-container{
    height: fit-content;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: #000; */
    margin-top: 11%;
}

.Blog-Upper-container{
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #e84a4a; */
}

.Blog-Upper-left{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background: #43d8e8; */
}

.Blog-Upper-left-title-container{
    height: fit-content;
    width: 100%;
    /* background: #db7f7f; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-heading-2);
    font-weight: 700;
    color: var(--color-2);
    text-transform: uppercase;
}

.Blog-Upper-left-detail-container{
    height: 10%;
    width: 100%;
    /* background: #e94545; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15%;
    font-size: var(--font-size-5);
    font-weight: 800;
    color: var(--color-2);
}

.Blog-Upper-left-description-container{
    font-size: var(--font-size-5);
    font-weight: 600;
    color: var(--color-2);
    word-spacing: 15px;
    line-height: 1.8;
}

.Blog-Upper-right{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    /* background: #437de8; */
}

.Blog-Upper-right-image-container{
    height: 90%;
    width: 80%;
    /* background: #000; */
}

.Blog-Upper-right-image-container > img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.Blog-bottom-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    /* background: #4ae84f; */
}

.Blog-bottom-left{
    height: 100%;
    width: 70%;
    /* background: #ec9f9f; */
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.Blog-bottom-left-primary-content{
    font-size: var(--font-size-4);
    font-weight: 300;
    text-align: justify;
    word-spacing: 10px;
    line-height: 1.3;
}

.Blog-bottom-left-secondary-content{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1vh;
}

.Blog-secondary-title{
    font-size: var(--font-size-3);
    font-weight: 700;
    color: var(--color-1);
    word-spacing: 5px;
    line-height: 1.8;
}

.Blog-secondary-description{
    font-size: var(--font-size-4);
    font-weight: 300;
    text-align: justify;
    word-spacing: 5px;
    line-height: 1.8;
}

.Blog-bottom-right{
    height: 100vh;
    width: 30vw;
    /* background: #62be25; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.Blog-bottom-right-title{
    height: 5vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-3);
    font-weight: 700;
    color: var(--color-1);
    word-spacing: 5px;
    line-height: 1.8;
    /* background: #de8989; */

}

.blog-bottom-right-recent-blog{
    height: fit-content;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background: #d94545; */
}

@media only screen and (max-device-width: 760px) {
    .Blog-Upper-container{
        flex-direction: column-reverse;
        height: fit-content;
        margin-bottom: 5vh;
    }

    .Blog-Upper-left{
        width:100%
    }

    .Blog-Upper-right{
        width: 100%;
    }

    .Blog-Upper-right-image-container{
        width: 100%;
    }

    .Blog-bottom-container{
        flex-direction: column;
    }

    .Blog-bottom-left{
        width: 100%;
    }
    
    .blog-bottom-right-recent-blog{
        flex-direction: column;
        gap: 3vh;
    }

    .Blog-bottom-right{
        width: 100%;
        height: fit-content;
    }

    .BlogBox2-main-container{
        height: fit-content;
        flex-direction: column;
    }

    .BlogBox2-image-container{
        width: 90%;
        height: 90%;
    }

    .BlogBox2-details_container{
        width: 100%;
    }
}

