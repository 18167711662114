.Header-main-container {
  height: fit-content;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0%;
  z-index: 999;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.header-top-main-container {
  /* height: 50%; */
  /* margin-top: 10px; */
  width: 90%;
  /* background: #b3dbd784; */
  padding: .5vh 1vw .5vh 1vw;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.7px solid rgb(192, 192, 192);
}

.headroof-left-container {
  width: 50%;
  display: flex;
}

.headroof-title {
  height: 100%;
  width: fit-content;
  border-radius: 10px;
  padding: 5px 20px 5px 20px;
  background-color: var(--color-1);
  color: var(--color-3);
  font-size: 0.8vw;
  font-weight: 400;
}

.headroof-title-container {
  width: 30%;
  /* background: #000; */
}

.headroof-contact-container {
  width: fit-content;
  display: flex;
  gap: 2vw;
  /* background: #e6e5e5; */
}

.headroof-content{
    font-size: var(--font-size-4);
    font-weight: 400;
    /* color: var(--color-5); */
}

.headroof-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.headroof-social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.Menu-container {
  width: auto;
  height: fit-content;
  background-color: transparent;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  font-family: "Poppins";
  justify-content: center;
  border: 0;
}
.contact-page-contact-container {
  width: 100%;
  height: auto;
  background-color: transparent;
  padding-left: 5%;
  cursor: pointer;
  flex-direction: row;
  font-family: "Poppins";
  justify-content: start;
  border: 0;
  flex: 10;
  font-weight: 700;
}

.header-bottom-main-container {
  height: fit-content;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #000; */
}

.logo-container {
  height: fit-content;
  width: 20vw;
}

.logo-container > img {
  width :100%;
  object-fit: contain;
}

.contact-menu-button{
  padding: 1vh 1vw 1vh 1vw;
  border: 0;
  font-size: 0.8vw;
  border-radius: 10px;
  background-color: var(--color-1);
  color: var(--color-3);
  cursor: pointer;
}

.socila-follows-us{
  font-size: 1.2vw;
  font-weight: 300;
}

.p-menubar .p-submenu-list{
  width: 15rem;
}




.hover-dropdown-menu {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.hover-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hover-dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

/* Menubar popover arrow */
.p-popover .p-popover-arrow {
  border-color: transparent transparent #ccc transparent;
}

/* Optional: Adjust popover position and size */
.p-popover {
  max-width: 200px;
}

/* Optional: Styling for the active menu item */
.p-menubar-root-list .p-menuitem-active {
  background-color: #f0f0f0;
}








@media only screen and (max-device-width: 760px) {
  .header-top-main-container, .contact-menu-button {
    display: none;
  }

  .logo-container{
    height: fit-content;
    width: 45vw;
  }

  
}