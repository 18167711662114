.rfidTwo-main{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* gap: 5vh; */
    background-color: #5EBD87;
}

.rfidTwo_title{
    height: 13vh;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    font-size: var(--font-size-2);
    font-weight: 800;
}


.rfidtwo-subtitle{
    height: fit-content;
    width: 90%;
    /* background: #21d63c; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.rfidtwo-subtitle-content{
    /* height: 50vh;
    width: 70%; */
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
    flex-wrap: wrap;
    gap: 1vw;
    border-radius: 5px;
    /* color:white; */
}

.rfidTwoContant-main{
    height: 70vh;
    width: 100%;
    background: white;
    display: flex;  
    align-items: center;
    justify-content: center;
}

.rfidtwoContant-section-left{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rfidtwoContent-image{
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    
    /* background: #000; */
}

.rfidtwoContent-image > img{
    height: 100%;
    width: 70%;
    object-fit: contain;
}

.rfidtwoContant-section-right{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rfidtwoContent-text{
    height: 80%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    background: white;
}

.Custom_Button{
    height: fit-content;
    width: fit-content;
    padding: 10px 20px 10px 20px;
    background: var(--color-1);
    color: var(--color-3);
    cursor: pointer;
    border-radius: 10px;
}

.active{
    color: var(--color-1);
    background: var(--color-3);
    font-weight: 900;
}


@media only screen and (max-device-width: 760px) {
    .erpsectionTwoContant-main{
        height: fit-content;
        flex-direction: column;
        padding-top: 10%;
        padding-bottom: 10%;
        gap: 5vh;
    }

    .erpsectiontwoContent-image{
        height: 100%;
    }

    .erpseciontwoContant-section-left, .erpseciontwoContant-section-right{
        width: 95%;
    }
    
    .erpsectiontwoContent-text {
        width: 90%;
    }
}