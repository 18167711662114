

.slide-form-button{
    /* position: fixed;
    top: 25%;
    right: 0;
    border: 0;
    height: 30vh;
    width: 3vw;
    background: #1e1e1e5d;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    font-size: var(--font-size-3);
    font-weight: 700;
    color: white;
    z-index: 999; */



    position: fixed;
    z-index: 99;
	top: 50%;
    right: -5%;
	transform:rotate(90deg);
	width: 15vw;
	height: 6vh;
	margin: 0;
    border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
    cursor: pointer;
    font-size: var(--font-size-3);
    font-weight: 400;
    
    /* writing-mode: vertical-lr;
 text-orientation: upright; */
}

.slide-form-button a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 10px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: black;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}
 .slide-form-button:hover a {
	letter-spacing: 3px;
    color: white;
}
 .slide-form-button a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
 .slide-form-button:hover a::before {
	transform: skewX(45deg) translate(200px);
}
 .slide-form-button::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 10px;
	background: var(--color-1);
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
 .slide-form-button:hover::before /*lightup button*/ {
	bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}

 .slide-form-button::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 10px;
	background: var(--color-1);
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
 .slide-form-button:hover::after /*lightup button*/ {
	top: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
 .slide-form-button:nth-child(1)::before, /*chnage 1*/
 .slide-form-button:nth-child(1)::after {
	background: var(--color-1);
	box-shadow: 0 0 5px var(--color-1), 0 0 15px var(--color-1), 0 0 30px var(--color-1),
		0 0 60px var(--color-1);
}

.space{
    height: 5%;
}

.sidebarStyles{
	width: 50vw;
}

.p-sidebar	{
	/* width: 50vw; */
    /* background-image: url('../../Assets/sidebar.jpg');
    background-repeat: no-repeat;
    background-size: cover; */
}

.form-container{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    /* background: #ee6c6c; */
}

.contact-form-input-warp{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
}

.label{
    font-size: var(--font-size-3);
    color: rgb(60, 60, 60);
    font-weight: 700;
}

.form-input{
    height: 5vh;
    width: 100%;
    border-radius: 10px;
    border: 0.8px solid rgb(60, 60, 60);
}

.form-text{
    height: 5vh;
    width: 100%;
    border-radius: 10px;
    border: 0.8px solid rgb(60, 60, 60);
}

.SlideControllButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

.ContactUs-left-address-sidebar{
    /* margin-left: 5vw; */
    font-size: var(--font-size-1);
    font-weight: 400;
    color: var(--color-2);
    text-align: left;
}

.ContactUs-main-container-sidebar{
	height: fit-content;
	width: 100%;
	/* background: #60b825; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.ContactUs-right-form-sidebar{
	height: fit-content;
	width: 100%;
	margin: 10px;
	padding: 15px;
	border-radius: 20px;
}

.form-container-sidebar{
	width: 100%;
}

.ContactUs-right-container-slidebar{
	height: fit-content;
	width: 100%;
	/* background: #f0c872; */
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.form-input-sidebar{
    height: 4vh;
    width: 100%;
    padding-left: 2%;
    border-radius: 10px;
    border: 0;
    background: linear-gradient(225deg, #efefef, #ffffff);
    box-shadow:  -9px 9px 17px #eeeeee,
                 9px -9px 17px #ffffff;
}

.form-text-sidebar{
	height: 8vh;
	width: 100%;
	  border-radius: 10px;
	  padding-left: 2%;
	  /* border: 0.8px solid rgb(60, 60, 60); */
	  border: 0;
	  background: linear-gradient(225deg, #efefef, #ffffff);
	  box-shadow:  -9px 9px 17px #eeeeee,
				   9px -9px 17px #ffffff;
  }

.contact-error{
    font-size: var(--font-size-4);
    font-weight: 500;
    color: red;
}

.contact-submit-sidebar{
    height: 5vh;
    width: 70%;
    border: 0;
    cursor: pointer;
    border-radius: 20px;
    font-size: var(--font-size-3);
    font-weight: 600;
    background-color: var(--color-1);
    color: var(--color-3);
}

@media only screen and (max-device-width: 760px) {
	.slide-form-button{
		display: none;
	}
}