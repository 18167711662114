.Website-main-container {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  gap: 2vh;
  /* position: absolute;
    top: 17%; */
}

.Websitecard-container {
  height: 35vh;
  width: 23%;
  display: flex;
  align-items: center;
  /* background-color: rgb(22, 205, 37); */
}
.Websitecard-box {
  height: 90%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  /* background: linear-gradient(145deg, #f5f5f5, #eeeeee); */
  box-shadow: 1px 1px 0px #e8e8e8, -1px -1px 0px #f3f3f3;
  margin: 2%;
  padding: 3%;
}

.websiteCard_image {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;

}
.websiteCard_image > img{
    height: 100%;
    width:30%;
    object-fit: contain;
    justify-content: center;
    align-items: center;
}

.websitecard-text-warp{
  height: 70%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  gap: 8%;
}
.websitecard-title{
    width: 100%;
    /* justify-content: center;
    align-items: center;
    display: flex; */
    font-size: var(--font-size-3);
    font-weight: bold;
    text-align: left; 
}
.websitecard-description{
    width: 100%;
    /* justify-content: center;
    align-items: center;
    display: flex; */
    text-align: left; 
    font-weight: 300;
    font-size: var(--font-size-4);
}

.webcontentWrapper{
  height: 40%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
}