.WhyGeneric-main-container{
    height: fit-content;
    width: 100%;
    /* background: #65d0c5; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5vh;
}

.WhyGeneric-button-container{
    height: 10vh;
    width: 100%;
    /* background: #e1128e; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

.WhyGeneric-Card-container{
    height: fit-content;
    width: 100%;
    /* background: #7ad763bb; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
    /* overflow: hidden; */
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    border-color: var(--color-1);
    background: transparent;
    color:var(--color-1)
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link:focus {
    box-shadow: none;
    /* background: transparent; */
}

