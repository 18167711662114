.Elearning-main-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
    /* position: absolute;
    top: 17%; */

}
.Sectionfourmain{
   
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background-color: blue; */
}
.sectionfour-left{
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}
.sectionfour-text{
    height: 100%;
    width: 80%;
    color: aliceblue;
}

.m-0{
    text-align: justify;
}
.m-1{
    text-align: justify;
}